<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование элемента"
        update
        action-one="hint/one"
        url-update="/scoring-criteria/update/:id"
        url-back="/scoring-criteria"
        :fields="fields"
    >
        <scoring-criteria-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import ScoringCriteriaForm from '@/components/forms/ScoringCriteriaForm.vue'
export default {
    name: 'ScoringCriteriaUpdate',
    components: { FormView, ScoringCriteriaForm },
    computed: {
        fields () {
            return [ 'name', 'content', 'type' ]
        }
    }
}
</script>